import { useCallback, useEffect, useMemo, useState } from 'react'

import Bugsnag from '@bugsnag/js'
import styled from '@emotion/styled'
import type { GetStaticPaths, GetStaticProps, NextPage } from 'next'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'

import { Header, spacing } from '@cash-design-system/react'
import { useLogCashWebCdfEvent, useLogPageViewEventstream } from '@cash-web/shared-feature-analytics'
import { ContactUs, qrHashes, QrThemes } from '@cash-web/support/ui-components'
import { useAnalyticsContext } from '@cash-web/support/utils'
import { CustomerSupportAccessViewNodes, WebViewViewPage } from '@squareup/customer-data-events-web'

import i18nextConfig, { fallbackLng as defaultLocale } from '../../next-i18next.config'
import ArticleList from '../../src/components/ArticleList'
import HelpContent from '../../src/components/helpContent'
import Search from '../../src/components/Search'
import StatusBadge from '../../src/components/StatusBadge'
import { getPublicRuntimeConfig } from '../../src/config/nextConfig'
import { defaultRegion, supportedRegions } from '../../src/config/regions'
import useCanonicalURL from '../../src/hooks/useCanonicalUrl'
import type { ArticleListing, ArticleListingWithToken } from '../../types'

const { deepLinkPath, qrPath } = getPublicRuntimeConfig()

interface HomeProps {
  data: { browse: ArticleListing[]; popularTopics: ArticleListing[] }
}

export const getStaticPaths: GetStaticPaths = () => ({
  paths: Object.keys(supportedRegions).map(region => ({ params: { region } })),
  fallback: 'blocking', // required for defaultRegion fallback in next.config.js
})

export const getStaticProps: GetStaticProps = async ({ locale = defaultLocale, params }) => {
  const region = params?.['region']?.toString().toLowerCase()

  if (!region || !Object.keys(supportedRegions).includes(region)) {
    return { notFound: true }
  }

  if (['default'].includes(locale)) {
    // Don't attempt to prerender the 'default' locale
    return { notFound: true }
  }

  const [browse, popularTopics] = await Promise.all<ArticleListing[]>([
    import(`../../data/${region.toLowerCase()}/browse`).then(data => data.default),
    import(`../../data/${region.toLowerCase()}/popularTopics`).then(data => data.default),
  ]).catch(err => {
    Bugsnag.notify(new Error(`Failed to load data for region "${region.toLowerCase()}", error: ${err}`))

    return [[], []] as ArticleListing[][]
  })

  return {
    props: {
      data: { browse, popularTopics },
      ...(await serverSideTranslations(locale, ['common'], i18nextConfig)),
    },
  }
}

const DESKTOP_QR_SIZE = 300

const StyledHeader = styled(Header)`
  margin-bottom: ${spacing.m};
`

const IndexPage: NextPage<HomeProps> = ({ data }) => {
  const { viewToken } = useAnalyticsContext()
  const canonicalUrl = useCanonicalURL()
  const { t } = useTranslation()
  const logPageViewEvent = useLogPageViewEventstream()
  const logCdfEvent = useLogCashWebCdfEvent()
  const router = useRouter()
  const [open, setOpen] = useState<boolean>(false)
  const desktopQRSize = DESKTOP_QR_SIZE
  const launchChatDeepLink = deepLinkPath('support/chat?page=help')

  const onHashChanged = () => {
    const hash = window.location.hash
    setOpen(hash === qrHashes['chat'])
  }

  const displayTypes = useMemo(
    () => [...data.popularTopics.map(() => 'suggested'), ...data.browse.map(() => 'node_tree')],
    [data.browse, data.popularTopics]
  )

  const nodeTokens = useMemo(
    () =>
      [...data.popularTopics, ...data.browse]
        .filter(item => 'token' in item)
        .map(item => (item as ArticleListingWithToken).token),
    [data.browse, data.popularTopics]
  )

  const logPageAnalytics = useCallback(() => {
    const totalCount = data.popularTopics.length + data.browse.length
    logCdfEvent(WebViewViewPage({ pageName: 'Support Page' }))
    logCdfEvent(
      CustomerSupportAccessViewNodes({
        displayTypes: displayTypes?.toString(),
        nodeTokens: nodeTokens?.toString(),
        parentToken: 'ROOT',
        totalCount: totalCount,
        trigger: 'HOME',
        viewToken: viewToken,
      })
    )
    logPageViewEvent({
      page: 'Cash App - Support',
    })
  }, [logCdfEvent, logPageViewEvent, data.browse, data.popularTopics, displayTypes, nodeTokens, viewToken])

  useEffect(() => {
    // check on initial page load
    if (typeof window !== 'undefined') {
      if (router.isReady) {
        logPageAnalytics()
      }

      onHashChanged()
    }

    window?.addEventListener('hashchange', onHashChanged)

    return () => {
      window?.removeEventListener('hashchange', onHashChanged)
    }
  }, [logPageAnalytics, router.isReady])

  const closeQrModal = useCallback(() => {
    // Remove #chat from the URL
    router.replace(
      {
        hash: '',
        pathname: router.pathname,
        query: { region: defaultRegion, ...router.query },
      },
      router.asPath.replace('#chat', '') == '/' ? '/' : undefined,
      { shallow: true }
    )
    setOpen(false)
  }, [router])

  return (
    <HelpContent>
      <Head>
        <title>{t('Cash App - Support', { context: 'home page title' })}</title>
        <meta
          name="description"
          content={t(
            'Get help using the Cash App and learn how to send and receive money without a problem using our support.',
            { context: 'home page metadata description' }
          )}
        />
        <link rel="canonical" href={canonicalUrl} />
      </Head>
      <section>
        <StatusBadge />
        <StyledHeader variant="largeLabel" as="h1">
          {t('How can we help?', { context: 'home intro' })}
        </StyledHeader>
        <Search showMoreResults />
      </section>
      <section>
        <ArticleList
          title={t('Popular Topics', { context: 'Subheading on the help landing page listing commonly used articles' })}
          data={data.popularTopics}
          tabletColumns={2}
          trigger="HOME"
        />
      </section>
      <section>
        <ArticleList title={t('Browse', { context: 'Browse subheading' })} data={data.browse} trigger="HOME" />
      </section>
      <section>
        <ContactUs
          mobileChatDeepLink={launchChatDeepLink}
          desktopQRPath={qrPath('launch/support/chat')}
          desktopQRSize={desktopQRSize}
          desktopQRTheme={QrThemes.LIGHT}
          open={open}
          onCloseQrModal={closeQrModal}
          cdfTrigger={{
            viewToken: viewToken,
            trigger: 'HOME',
            triggerNodeToken: 'ROOT',
          }}
          t={t}
        />
      </section>
    </HelpContent>
  )
}

export default IndexPage
